import React from 'react';
import { Link } from 'gatsby';
import { BsArrowLeftSquare } from 'react-icons/bs';
import { getIconComp } from '../backend/categoriesIcon';

import categoryData from '../data/categories.json';

const CategoriesMenu = ({ activeCategory }) => {
    return (
        <div>
            <div className="category-menu">
                {categoryData.map((cat, key) => (
                    <Link
                        to={`/category/${cat.category_id}`}
                        key={key}
                        className={
                            activeCategory === cat.category_id
                                ? 'cat-menu active-cat'
                                : 'cat-menu'
                        }
                    >
                        {getIconComp(cat.category_id)}
                        <div className="title">{cat.fieldValue}</div>
                    </Link>
                ))}
            </div>

                     <div className="sidebar-footer">
               <p>
                    InsightWorthy is a product of<br />
                    <a href="https://5000fish.com" target="_blank">
                        5000fish, Inc.
                    </a>{' '}
                    &copy; 5000fish 2023
                </p>
            </div>
                        
        </div>
    );
};

export default CategoriesMenu;
