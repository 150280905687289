import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { MdAdd } from 'react-icons/md';

import CategoriesMenu from '../comp/categoriesMenu';
import { compare } from '../backend/functions';
import CardBox from '../comp/cardBox';
import HeadNav from '../comp/headNav';
import SEO from '../comp/seo';
import '../styles/home.scss';

const PageTemplate = ({ data, pageContext }) => {
    const list = data.apisArray.nodes;
    const pageCategory = list[0].category;
    return (
        <>
            <HeadNav />
            <SEO
                title={`List of ${pageCategory} Key Performance Indicators —  KPI Directory by InsightWorthy`}
                description="Comprehensive list of over 1000 key metrics and KPIs for business leaders and analysts, spanning sales, marketing, SaaS, manufacturing, e-commerce, social media, customer service, project management, finance, and more."
                keywords="KPI, metrics, performance, business intelligence, analytics, data analysis, dashboards, reports, business, public, data, free"
            />
            <div className="container">
                <div className="home-layout">
                    <CategoriesMenu activeCategory="" />
                    <main className="main">
                        <h2 className="pageTitle">{list[0].category}</h2>
                        <div className="api-list">
                            {list.map((api, key) => (
                                <CardBox key={key} api={api} cardKey={key} />
                            ))}
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default PageTemplate;

export const pageQuery = graphql`
    query($skip: Int, $limit: Int, $cat_id: String) {
        apisArray: allPaApis(
            sort: { fields: published_at, order: DESC }
            skip: $skip
            limit: $limit
            filter: { category_id: { eq: $cat_id } }
        ) {
            nodes {
                id
                category_id
                category
                published_at
                description
                website
                slug
                title
                createdAt
                logo
                keywords
            }
        }
    }
`;
